import React, { useState } from "react";
// import AppModal from "../components/AppModal";
// import QR from "../assets/images/banner/qr.png";
import { Link } from "react-router-dom";
import Banner from "../assets/images/banner/donate.png";
// import DonateForm from "./DonateForm";

import cardImg from "../assets/images/card.png";
import cryptImg from "../assets/images/cryto.png";
import cardblue from "../assets/images/card-blue.png";
import cardwhite from "../assets/images/card-white.png";
import cryptoblue from "../assets/images/crypto-blue.png";
import cryptowhite from "../assets/images/crypto-white.png";

function Donate() {
  // const [isModalOpen, setModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  // const [showError, setShowError] = useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [showOptionError, setShowOptionError] = useState(false);

  const formattedAmount = enteredAmount
    .replace(/[^0-9]/g, "")
    .replace(/^0+/, "");

  // const handleCloseModal = () => {
  //   setModalOpen(false);
  // };
  // function handlePayNow() {
  //   if (!selectedOption || formattedAmount === "") {
  //     setShowError(true);
  //   } else {
  //     setShowError(false); // Clear the error if everything is valid
  //     console.log(`Processing payment for ${selectedOption}`);
  //   }
  // }
  function handlePayNow() {
    // setShowAmountError(false);
    setShowOptionError(false);

    if (formattedAmount === "") {
      setShowAmountError(true);
    }
    if (!selectedOption) {
      setShowOptionError(true);
    }

    if (formattedAmount !== "" && selectedOption) {
      console.log(`Processing payment for ${selectedOption}`);
    }

    if (selectedOption === "option1") {
      window.open("https://buy.stripe.com/3csbLf9Lz6Rf8j6aEF", "_blank");
      setModalOpen(false);
    } else if (selectedOption === "option2") {
      // Redirect to the Crypto payment page
      window.open(
        "https://commerce.coinbase.com/checkout/40445f4a-9d1c-4814-85d6-0618714b4cf5",
        "_blank"
      );
      setModalOpen(false);
    }

    // close modal
  }
  const handleOptionClick = (option) => {
    setSelectedOption((prevOption) => (prevOption === option ? null : option));
  };
  return (
    <div className='main-div'>
      {/* <AppModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className='qr-wallet'>
          <h4 className='title'>Wallet Address</h4>
          <div className='qr-wrap'>
            <img className='img-responsive qr-code' src={QR} alt='' />
          </div>
          <p className='wallet-address'>
            terra14rz46hp3rg5tvshdsjl5mmtcr9s7cputfpacwm
          </p>
        </div>
      </AppModal> */}
      <section className='banner'>
        <div className='wrapper container flex-donate'>
          <div className='flex-home'>
            <h2>Donate</h2>
            <div>
              <p>
                When you donate to Terra Classic Foundation, you join a
                community of Luncatics who believe that collective action can
                secure, advance and decentralise the Terra Classic ecosystem.{" "}
              </p>
              {/* <Link id='myBtn'>Donate now</Link> */}
            </div>
          </div>
          <div className='flex-img'>
            <img className='donate-hero-img' src={Banner} alt='' />
          </div>
        </div>
      </section>
      <section className='donate-page-form'>
        <div className='donate-modal'>
          <div className='modal-content'>
            {/* <button className='close-btn' onClick={() => setModalOpen(false)}>
              &times;
            </button> */}
            {/* Heading */}
            {/* <h2>Donate</h2> */}
            <p className='copy-head'>
              Select your payment option to make a donation
            </p>
            {/* Amount Input */}
            <div className='amount-input'>
              {/* <label htmlFor='amount'>Add an Amount (USD):</label> */}
              {/* <div className='input-wrapper'>
                  <input
                    type='text'
                    id='amount'
                    value={enteredAmount === "" ? "" : `$${formattedAmount}`}
                    onChange={(e) => setEnteredAmount(e.target.value)}
                    placeholder='Amount in USD'
                  />
                  {showAmountError && (
                    <p className='error-message-0'>Please enter an amount.</p>
                  )}
                </div> */}
            </div>
            {/* Payment Options */}
            <div className='options'>
              {/* <p>Select Payment Method</p> */}
              <div className='payment-div'>
                <span>
                  <button
                    className={selectedOption === "option1" ? "selected" : ""}
                    onClick={() => handleOptionClick("option1")}
                  >
                    <span className='wrapper-icon'>
                      <img
                        className='credit-card'
                        src={
                          selectedOption === "option1" ? cardwhite : cardblue
                        }
                        alt='credit card icon'
                      />
                    </span>
                  </button>
                  <p
                    className={
                      selectedOption === "option1"
                        ? "white-color"
                        : "black-color"
                    }
                  >
                    Credit/Debit <br className='hidden-lg' /> Card
                  </p>
                </span>
                {/*  */}
                <p className='or-name'>or</p>
                <span>
                  <button
                    className={selectedOption === "option2" ? "selected" : ""}
                    onClick={() => handleOptionClick("option2")}
                  >
                    <span className='wrapper-icon'>
                      {" "}
                      <img
                        className='crypto-card'
                        src={
                          selectedOption === "option2"
                            ? cryptowhite
                            : cryptoblue
                        }
                        alt='crypto icon'
                      />
                    </span>
                  </button>{" "}
                  <p
                    className={
                      selectedOption === "option2"
                        ? "white-color"
                        : "black-color"
                    }
                  >
                    Crypto
                    <br className='hidden-lg' />
                    currency
                  </p>{" "}
                </span>
              </div>
              {showOptionError && (
                <p className='error-message-1'>
                  Please select a payment option.
                </p>
              )}
            </div>
            {/* Total Amount */}
            {/* <div className='total-amount'>
                Total Amount:{" "}
                {enteredAmount === "" ? "$0" : `$${formattedAmount}`}
              </div> */}
            {/* Pay Now Button */}
            <button
              className='pay-now-btn'
              // disabled={!selectedOption || formattedAmount === ""}
              onClick={handlePayNow}
            >
              Donate
            </button>
          </div>
        </div>
      </section>
      <section className='about grey'>
    
        <div className='wrapper container'>
          <h2 className='donate-copy-header'> Support Our Mission</h2>
          <div className='column-wrap-page'>
            <p>
              Your donation supports our collective mission for the Terra
              Classic blockchain and helps us run programs (such as the
              Delegation Program, Liquidity Program, Server Program, etc) and governance
              proposals.{" "}
            </p>
            <p>
              We advocate for the Terra Classic community and ecosystem, but we
              can't do it alone - help us by donating today.
            </p>
            <p>
              For transparency please find our Terra Classic wallet
              address&nbsp;
              <Link
                className='link-a'
                to='https://finder.station.money/classic/address/terra14rz46hp3rg5tvshdsjl5mmtcr9s7cputfpacwm'
                target='_blank'
              >
                here
              </Link>
                  .
            </p>
                  <p>
              For transparency please find our TRC20 wallet
              addresses&nbsp;
              <Link
                className='link-a'
                to='https://tronscan.org/#/address/TT7QwWH3uuf56XnzkcXHT6C2CGXnujN1Xa'
                target='_blank'
              >
                here - wallet 1&nbsp;
              </Link>
               and&nbsp;
              <Link
                className='link-a'
                to='https://tronscan.org/#/address/TTSqFUhbv8Jq7VrhsoCmbZB8wbW5hKyKcp'
                target='_blank'
              >
                here - wallet 2
              </Link>
            .
                  </p>
            <p>
              {/* <Link
                className='link-a'
                to='https://finder.terra.money/classic/address/terra14rz46hp3rg5tvshdsjl5mmtcr9s7cputfpacwm'
                target='_blank'
              >
                https://finder.terra.money/classic/address/terra14rz46hp3rg5tvshdsjl5mmtcr9s7cputfpacwm
              </Link> */}
            </p>
          </div>
          {/* <DonateForm /> */}
        </div>
      </section>
    </div>
  );
}

export default Donate;
